@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css?family=Open+Sans:400,600,700&display=swap');

html {
  height: 100%;
  overflow-y: overlay;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100vh;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}
 /* Sentry Feedback Button Styling */
#custom-feedback-btn {
  --background: #f0f0f0;
  --foreground: rgb(2, 66, 176);
  --accent-background: #f0f0f0;
  --accent-foreground: black;
  --button-primary-border: 1px solid #c3c3c3;
  --border: 1.5px solid rgb(2, 66, 176);
  --inset: auto auto 0 0;
  --outline: none !important;
  --actor-color: rgb(2, 66, 176);
  --dialog-color: black;
  --dialog-border: 1.5px solid #c3c3c3;
  --dialog-background: #f0f0f0;
  --input-border: 1px solid #c3c3c3;
  --input-color: black;
  --button-border:1px solid #c3c3c3;
  --button-color: black;
  --button-hover-background: #fbfbfb;
  --interactive-filter: none;
  --button-primary-hover-background: #fbfbfb;
  --actor-hover-background: #fbfbfb;
}

@media screen and (max-width: 640px) {
  #custom-feedback-btn {
    --inset: 0 0 auto auto;
  }
}

@layer utilities {
  /* Chrome, Safari and Opera */
  .no-scrollbar::-webkit-scrollbar {
    display: none;
  }

  .no-scrollbar {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }
}
